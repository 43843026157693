import { Body, Button, Modal, useTheme } from "@merit/frontend-components";
import { Constants } from "@src/utils";
import { StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { ViewStyle } from "react-native";

type Props = {
  readonly onClose: () => void;
  readonly onPressDelete: () => void;
};

export const SharedLinkDeleteConfirmationModal = ({ onClose, onPressDelete }: Props) => {
  const { _ } = useLingui();
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly actionsContainer: ViewStyle;
    readonly cancelButtonContainer: ViewStyle;
  }>({
    actionsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingTop: theme.spacing.l,
    },
    cancelButtonContainer: {
      marginRight: theme.spacing.s,
    },
  });

  return (
    <Modal
      maxWidth={Constants.Sizes.MODAL}
      onClose={onClose}
      title={_(msg({ context: "title", message: "Delete shared link" }))}
      titleIconName="warningMediumCritical"
      width="100%"
    >
      <Body>
        <Trans>
          Deleting this shared link will immediately delete the shared page you created. Are you
          sure you want to delete this shared link?
        </Trans>
      </Body>
      <View style={styles.actionsContainer}>
        <View style={styles.cancelButtonContainer}>
          <Button
            onPress={onClose}
            text={_(msg({ context: "dismiss dialog", message: "Cancel" }))}
            type="secondary"
          />
        </View>
        <Button
          onPress={onPressDelete}
          text={_(msg({ context: "action", message: "Delete" }))}
          type="destructive"
        />
      </View>
    </Modal>
  );
};
