import { Body } from "@merit/frontend-components";
import { Dialog } from "@src/components";
import { Keyboard, Platform, Pressable, StyleSheet } from "react-native";
import { MAX_NUM_SELECTED } from "./common";
import { MeritsHeader } from "./MeritsHeader.mobile";
import { SearchableMeritsList } from "./SearchableMeritsList";
import { Trans, msg } from "@lingui/macro";
import { useAcceptMerit } from "@src/api/issuance";
import { useAlerts, useNavigation } from "@src/hooks";
import { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { useShareMeritsStore } from "@src/stores/shareMerits";
import { useTooManySelectedAlertParams } from "./useTooManySelectedAlertParams";
import type { Merit } from "@src/api/issuance";

const MeritsScreen = () => {
  const styles = StyleSheet.create({ fullFlex: { flex: 1 } });

  const navigation = useNavigation();
  const { _ } = useLingui();
  const acceptMerit = useAcceptMerit();
  const { sendAlert } = useAlerts();
  const { tooManySelectedAlertParams } = useTooManySelectedAlertParams({ limit: MAX_NUM_SELECTED });

  const setStoredSelectedMeritIds = useShareMeritsStore(state => state.setSelectedMeritIds);

  const [isShareInProgress, setIsShareInProgress] = useState(false);
  const [selectedMeritIds, setSelectedMeritIds] = useState<ReadonlySet<Merit["id"]> | undefined>();
  const [meritToAccept, setMeritToAccept] = useState<Merit | undefined>();

  // reset state for sharing if done/canceled
  useEffect(() => {
    if (!isShareInProgress) {
      setSelectedMeritIds(undefined);
    }
  }, [isShareInProgress]);

  const onPressShare = () => {
    setIsShareInProgress(true);
  };

  const onPressCancel = () => {
    setIsShareInProgress(false);
    setStoredSelectedMeritIds([]);
  };

  const onPressPreview = () => {
    setIsShareInProgress(false);
    if (selectedMeritIds !== undefined) {
      setStoredSelectedMeritIds([...selectedMeritIds]);
      navigation.navigate("CreateShareMeritsConfirmation");
    }
  };

  const onPressItem: React.ComponentProps<typeof SearchableMeritsList>["onPressItem"] = merit => {
    if (!isShareInProgress) {
      navigation.navigate("MeritDetails", { meritId: merit.id });

      return;
    }

    if (merit.state?.name === "pending") {
      setMeritToAccept(merit);

      return;
    }

    const isInSet = selectedMeritIds?.has(merit.id) === true;
    if (!isInSet && (selectedMeritIds?.size ?? 0) >= MAX_NUM_SELECTED) {
      sendAlert(tooManySelectedAlertParams);

      return;
    }

    const newSet = new Set(selectedMeritIds);
    if (selectedMeritIds?.has(merit.id) === true) {
      newSet.delete(merit.id);
    } else {
      newSet.add(merit.id);
    }
    setSelectedMeritIds(newSet);
  };

  const content = (
    <>
      <MeritsHeader
        isPreviewButtonEnabled={(selectedMeritIds?.size ?? 0) > 0}
        isShareInProgress={isShareInProgress}
        onPressCancel={onPressCancel}
        onPressPreview={onPressPreview}
        onPressShare={onPressShare}
      />
      <SearchableMeritsList
        isShareInProgress={isShareInProgress}
        onPressItem={onPressItem}
        selectedMeritIds={selectedMeritIds}
      />
      {meritToAccept !== undefined && (
        <Dialog
          actionButtonProps={{
            onPress: () => {
              acceptMerit.mutateAsync(meritToAccept.id);
              setMeritToAccept(undefined);
            },
            text: acceptMerit.isPending ? _(msg`Accepting…`) : _(msg`Accept`),
            type: "primary",
          }}
          cancelButtonProps={{
            text: _(msg`Cancel`),
          }}
          content={
            <Body>
              <Trans>You may only share accepted Merits. Accept {meritToAccept.name}?</Trans>
            </Body>
          }
          onClose={() => {
            setMeritToAccept(undefined);
          }}
          title={_(msg`Accept This Merit?`)}
        />
      )}
    </>
  );

  return Platform.OS === "web" ? (
    content
  ) : (
    <Pressable
      onPress={() => {
        Keyboard.dismiss();
      }}
      style={styles.fullFlex}
    >
      {content}
    </Pressable>
  );
};

export { MeritsScreen };
