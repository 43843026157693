import { MAX_NUM_SELECTED } from "./common";
import { MeritsList } from "./MeritsList";
import { SideBarContentHeader } from "./SideBarContentHeader";
import { byNameThenOrg, containsText, hasState } from "@src/utils";
import { useAlerts, useNavigation } from "@src/hooks";
import { useEffect, useMemo, useState } from "react";
import { useMerits } from "@src/api/issuance";
import { useShareMeritsStore } from "@src/stores/shareMerits";
import { useTooManySelectedAlertParams } from "./useTooManySelectedAlertParams";
import type { Merit } from "@src/api/issuance";

export const MeritsScreen = () => {
  const { sendAlert } = useAlerts();
  const { data: merits } = useMerits();
  const navigation = useNavigation();
  const { tooManySelectedAlertParams } = useTooManySelectedAlertParams({ limit: MAX_NUM_SELECTED });

  const setStoredSelectedMeritIds = useShareMeritsStore(state => state.setSelectedMeritIds);

  const [searchText, setSearchText] = useState("");
  const [isShareInProgress, setIsShareInProgress] = useState(false);
  const [selectedMeritIds, setSelectedMeritIds] = useState<ReadonlySet<Merit["id"]> | undefined>();

  // reset state for sharing if done/canceled
  useEffect(() => {
    if (!isShareInProgress) {
      setSelectedMeritIds(undefined);
    }
  }, [isShareInProgress]);

  const displayedMerits = useMemo(
    () =>
      merits
        ?.filter(hasState(["accepted", "pending"]))
        .filter(containsText(searchText))
        .sort(byNameThenOrg),
    [merits, searchText]
  );

  const handleChangeText = (text: string) => {
    setSearchText(text);
  };

  const onPressShare = () => {
    setIsShareInProgress(true);
  };

  const onPressCancel = () => {
    setIsShareInProgress(false);
    setStoredSelectedMeritIds([]);
  };

  const onPressPreview = () => {
    setIsShareInProgress(false);
    if (selectedMeritIds !== undefined) {
      setStoredSelectedMeritIds([...selectedMeritIds]);
      navigation.navigate("CreateShareMeritsConfirmation");
    }
  };

  const onPressItem: React.ComponentProps<typeof MeritsList>["onPressItem"] = meritId => {
    if (!isShareInProgress) {
      navigation.navigate("MeritDetails", { meritId });

      return;
    }

    const isInSet = selectedMeritIds?.has(meritId) === true;
    if (!isInSet && (selectedMeritIds?.size ?? 0) >= MAX_NUM_SELECTED) {
      sendAlert(tooManySelectedAlertParams);

      return;
    }

    const newSet = new Set(selectedMeritIds);
    if (isInSet) {
      newSet.delete(meritId);
    } else {
      newSet.add(meritId);
    }
    setSelectedMeritIds(newSet);
  };

  return (
    <>
      <SideBarContentHeader
        isPreviewButtonEnabled={(selectedMeritIds?.size ?? 0) > 0}
        isShareInProgress={isShareInProgress}
        onChangeText={handleChangeText}
        onPressCancel={onPressCancel}
        onPressPreview={onPressPreview}
        onPressShare={onPressShare}
      />
      <MeritsList
        isShareInProgress={isShareInProgress}
        merits={displayedMerits}
        onPressItem={onPressItem}
        selectedMeritIds={selectedMeritIds}
      />
    </>
  );
};
