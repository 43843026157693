import { HeaderGoBack } from "@src/components";
import { SharedLinksList } from "./SharedLinksList.mobile";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

export const SharedLinksScreen = () => {
  const { _ } = useLingui();

  return (
    <>
      <HeaderGoBack title={_(msg({ context: "title", message: "Active Shared Links" }))} />
      <SharedLinksList />
    </>
  );
};
