import { Body, Button, Checkbox, useTheme } from "@merit/frontend-components";
import { ORG_LOGO_SIZE } from "@src/utils/constants/sizes";
import { OrgLogo } from "../OrgLogo";
import { PendingStateIndicator } from "../PendingStateIndicator";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { TruncatedBody } from "../TruncatedBody";
import { msg } from "@lingui/macro";
import { useAcceptMerit } from "@src/api/issuance";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { Props } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const MeritListItemDesktop = ({
  isSelected = false,
  isShareInProgress = false,
  merit,
  onPress,
}: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly flex: ViewStyle;
    readonly issuerImageContainer: ViewStyle;
    readonly item: ViewStyle;
    readonly nameContainer: ViewStyle;
    readonly imageNameContainer: ViewStyle;
    readonly subduedText: TextStyle;
    readonly createdAt: TextStyle;
    readonly issuerOrg: TextStyle;
  }>({
    createdAt: {
      flexBasis: 50,
    },
    flex: {
      flex: 1,
    },
    imageNameContainer: {
      display: "flex",
      flexBasis: 500,
      flexDirection: "row",
      flexGrow: 6,
    },
    issuerImageContainer: {
      borderRadius: theme.borderRadii.m,
      height: ORG_LOGO_SIZE,
      marginRight: theme.spacing.l,
      width: ORG_LOGO_SIZE,
    },
    issuerOrg: {
      flexGrow: 2,
    },
    item: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    nameContainer: {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    subduedText: {
      color: theme.colors.text.subdued,
    },
  });

  const getTestProps = useGetTestProps();
  const route = useRoute();
  const acceptMerit = useAcceptMerit();
  const { _ } = useLingui();

  const isMeritAccepted = merit.state?.name === "accepted";

  const rightSideElements = (() => {
    if (!isShareInProgress) {
      return (
        <>
          <TruncatedBody
            style={[styles.subduedText, styles.issuerOrg]}
            testProps={{
              elementId: "issuingOrgName",
              elementName: "MeritListItem",
              screenName: route.name,
            }}
          >
            {merit.transformedFields.orgName}
          </TruncatedBody>
          {merit.createdAt === undefined ? null : (
            <Body style={[styles.subduedText, styles.createdAt]}>
              {Intl.DateTimeFormat("default", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }).format(new Date(merit.createdAt))}
            </Body>
          )}
        </>
      );
    }

    if (merit.state?.name === "pending") {
      return (
        <Button
          disabled={acceptMerit.isPending}
          onPress={() => {
            acceptMerit.mutateAsync(merit.id);
          }}
          text={_(msg({ context: "action", message: "Accept" }))}
          type="primary"
        />
      );
    }

    return (
      <Checkbox
        defaultChecked={isSelected}
        disabled={!isMeritAccepted}
        onChange={_isChecked => {
          onPress?.();
        }}
      />
    );
  })();

  return (
    <TouchableOpacity
      disabled={isShareInProgress && !isMeritAccepted}
      onPress={isShareInProgress && !isMeritAccepted ? undefined : onPress}
      {...getTestProps({
        elementId: merit.id,
        elementName: "MeritListItem",
      })}
      style={styles.item}
    >
      <View style={styles.imageNameContainer}>
        <View style={styles.issuerImageContainer}>
          <OrgLogo merit={merit} />
          {merit.state?.name === "pending" ? <PendingStateIndicator /> : null}
        </View>
        <View style={styles.nameContainer}>
          <TruncatedBody
            size="l"
            style={[styles.flex, { fontWeight: theme.fontWeights.semiBold }]}
            testProps={{
              elementId: "meritName",
              elementName: "MeritListItem",
              screenName: route.name,
            }}
          >
            {merit.name}
          </TruncatedBody>
        </View>
      </View>
      {rightSideElements}
    </TouchableOpacity>
  );
};

export { MeritListItemDesktop };
