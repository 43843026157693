import { Configuration as ConfigurationCls, SharesApi } from "@merit/share-merits-client";
import { getLocalConfig } from "@src/utils";
import { useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

const useShareMeritsApi = () => {
  const { accessToken } = useMeritAuth0();

  return {
    api: new SharesApi(
      new ConfigurationCls({
        ...config.api.shareMeritsBackend,
        headers: {
          ...config.api.shareMeritsBackend.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isReady: accessToken !== "",
  };
};

export { useShareMeritsApi };
