import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { SendAlertParams } from "@src/hooks";

type Options = {
  readonly limit: number;
};

type UseTooManySelectedAlertParams = (opts: Options) => {
  readonly tooManySelectedAlertParams: SendAlertParams;
};

export const useTooManySelectedAlertParams: UseTooManySelectedAlertParams = ({ limit }) => {
  const { _ } = useLingui();

  return {
    tooManySelectedAlertParams: {
      id: "tooManySelected",
      text1: _(msg`Share Limit Reached`),
      text2: _(msg`Only ${limit} Merits can be shared at once.`),
      type: "warning",
    },
  };
};
