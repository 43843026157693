import { SharedLinksList } from "./SharedLinksList.desktop";
import { SideBarContentHeader } from "./SideBarContentHeader";
import React from "react";

export const SharedLinksScreen = () => (
  <>
    <SideBarContentHeader />
    <SharedLinksList />
  </>
);
