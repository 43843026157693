import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMutation } from "@tanstack/react-query";
import { useShareMeritsApi } from "./useShareMeritsApi";
import type { SharesApiDeleteShareRequest } from "@merit/share-merits-client";

export const useDeleteSharedLink = () => {
  const { api } = useShareMeritsApi();
  const { deleteAlert, sendAlert } = useAlerts();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: (shareID: SharesApiDeleteShareRequest["shareID"]) => api.deleteShare({ shareID }),
    onError: (err, shareID) => {
      Log.error(`Error deleting shared links`, { error: err, shareID });
      deleteAlert("deleteSharedLink-deleting");
      sendAlert({
        id: "deleteSharedLink-error",
        text1: _(msg`Something went wrong`),
        text2: _(msg`Error deleting shared link`),
        type: "error",
      });
    },
    onMutate: () => {
      sendAlert({
        id: "deleteSharedLink-deleting",
        text1: _(msg`Deleting…`),
        type: "info",
      });
    },
    onSuccess: () => {
      deleteAlert("deleteSharedLink-deleting");
      sendAlert({
        id: "deleteSharedLink-success",
        text1: _(msg`Success`),
        text2: _(msg`Your shared link has been deleted successfully`),
        type: "success",
      });
    },
  });
};
