import {
  Body,
  Button,
  Heading,
  Icon,
  LineSeparator,
  RadioButtonGroup,
  TextInput,
  useTheme,
} from "@merit/frontend-components";
import { FlatList, StyleSheet, View } from "react-native";
import { Header, KeyboardAvoidingView, MeritListItem } from "@src/components";
import { Plural, Trans, msg } from "@lingui/macro";
import { ShareOrCopyLinkButton } from "./ShareOrCopyLinkButton";
import { getLocalConfig } from "@src/utils";
import { useCreateShareMerits } from "@src/api/share-merits";
import { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { useMerits } from "@src/api/issuance";
import { useNavigation } from "@src/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useShareMeritsStore } from "@src/stores/shareMerits";
import type { TextStyle, ViewStyle } from "react-native";

const MIN_DAYS = 1;
const MAX_DAYS = 180;
const NOT_NUMBER_REGEX = /[^\d]/gu;

const { shareMeritsFrontend } = getLocalConfig();

export const CreateShareMeritsConfirmation = () => {
  const { bottom: bottomSafeInset, top: topSafeInset } = useSafeAreaInsets();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly headerContainer: ViewStyle;
    readonly radioButtonsContainer: ViewStyle;
    readonly selectedMeritsOuter: ViewStyle;
    readonly successContainer: ViewStyle;
    readonly successButtonsContainer: ViewStyle;
    readonly successLinkContainer: ViewStyle;
    readonly textInputErrorText: TextStyle;
    readonly textInputFieldContainer: TextStyle;
    readonly textInputFieldWithDays: TextStyle;
    readonly daysText: TextStyle;
    readonly expireHeaderText: TextStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    contentContainer: {
      alignItems: "center",
      alignSelf: "center",
      flex: 1,
      maxWidth: 1280,
      paddingBottom: Math.max(theme.spacing.xl, bottomSafeInset),
      paddingHorizontal: theme.spacing.xl,
      width: "100%",
    },
    daysText: {
      fontSize: theme.fontSizes.m.fontSize,
      paddingLeft: theme.spacing.s,
      paddingVertical: theme.spacing.s,
    },
    expireHeaderText: {
      fontSize: theme.fontSizes.m.fontSize,
      fontWeight: theme.fontWeights.semiBold,
    },
    headerContainer: {
      alignItems: "center",
    },
    radioButtonsContainer: {
      marginBottom: theme.spacing.l,
    },
    selectedMeritsOuter: {
      alignSelf: "stretch",
      flex: 1,
      marginBottom: theme.spacing.l,
    },
    successButtonsContainer: {
      alignItems: "flex-end",
    },
    successContainer: {
      rowGap: theme.spacing.l,
    },
    successLinkContainer: {
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      padding: theme.spacing.s,
    },
    textInputErrorText: {
      color: theme.colors.text.alert.critical,
      fontSize: theme.fontSizes.s.fontSize,
    },
    textInputFieldContainer: {
      marginLeft: theme.spacing.xxl,
      paddingLeft: theme.spacing.s,
      paddingVertical: theme.spacing.s,
    },
    textInputFieldWithDays: {
      alignItems: "center",
      flexDirection: "row",
    },
  });

  const { goBackOrGoHome, goHome } = useNavigation();
  const { _ } = useLingui();
  const { data: merits } = useMerits();
  const { isPending, mutate } = useCreateShareMerits();

  const selectedMeritIds = useShareMeritsStore(state => state.selectedMeritIds);

  const [isExpirationOptionSelected, setIsExpirationOptionSelected] = useState<boolean>(true);
  const [daysToExpire, setDaysToExpire] = useState<string>("");
  const [generatedShareLink, setGeneratedShareLink] = useState<string | undefined>();
  const [isDaysInputErrorVisible, setIsDaysInputErrorVisible] = useState<boolean>(false);

  // take the user home if they navigate directly to this screen with an invalid state
  useEffect(() => {
    if (selectedMeritIds.length === 0) {
      goHome();
    }
  }, [goHome, selectedMeritIds.length]);

  const onPressGenerateShareLink = () => {
    mutate(
      {
        containers: selectedMeritIds.map(containerID => ({
          containerID,
        })),
        expiration: daysToExpire.length > 0 ? `${daysToExpire}d` : undefined,
      },
      {
        onSuccess: ({ shareID }) => {
          setGeneratedShareLink(`${shareMeritsFrontend.url}share/${shareID}`);
        },
      }
    );
  };

  const handleInputChange = (value: string) => {
    const numericValue = value.replace(NOT_NUMBER_REGEX, "");

    if (numericValue.length > 0) {
      const number = parseInt(numericValue, 10);

      if (number < MIN_DAYS || number > MAX_DAYS) {
        setIsDaysInputErrorVisible(true);
      } else {
        setIsDaysInputErrorVisible(false);
      }

      setDaysToExpire(numericValue);
    } else {
      setDaysToExpire("");
      setIsDaysInputErrorVisible(false);
    }
  };

  return (
    <KeyboardAvoidingView keyboardVerticalOffset={topSafeInset} style={styles.container}>
      <Header
        containerStyle={styles.headerContainer}
        leftElement={<Icon name="closeLargeAction" />}
        leftOnPress={() => {
          goBackOrGoHome();
        }}
        rightElement={<View /> /* for preserving layout */}
        title={
          generatedShareLink === undefined
            ? _(msg({ context: "title", message: "Ready to Share?" }))
            : _(msg({ context: "title", message: "Success" }))
        }
      />
      <View style={styles.contentContainer}>
        {generatedShareLink === undefined ? (
          <>
            <Heading level="3">
              <Plural
                one={<Trans>You have selected the following merit to share:</Trans>}
                other={<Trans>You have selected the following # merits to share:</Trans>}
                value={selectedMeritIds.length}
              />
            </Heading>
            <FlatList
              ItemSeparatorComponent={LineSeparator}
              data={selectedMeritIds}
              keyExtractor={item => item}
              renderItem={({ item }) => {
                const matchingMerit = merits?.find(merit => merit.id === item);

                return matchingMerit === undefined ? null : <MeritListItem merit={matchingMerit} />;
              }}
              style={styles.selectedMeritsOuter}
            />
            <View style={styles.radioButtonsContainer}>
              <Body style={styles.expireHeaderText}>{_(msg`I want this link to:`)}</Body>
              <RadioButtonGroup
                initSelected={isExpirationOptionSelected}
                onChange={val => {
                  setIsExpirationOptionSelected(val);
                  if (val) {
                    setDaysToExpire("");
                    setIsDaysInputErrorVisible(false);
                  }
                }}
                options={[
                  {
                    label: _(msg`never expire`),
                    value: true,
                  },
                  {
                    label: _(msg`expire after a period of time`),
                    value: false,
                  },
                ]}
              />
              {!isExpirationOptionSelected && (
                <View style={styles.textInputFieldContainer}>
                  <View style={styles.textInputFieldWithDays}>
                    <TextInput
                      autoFocus
                      keyboardType="number-pad"
                      maxLength={3}
                      onChangeText={handleInputChange}
                      size="small"
                      value={daysToExpire}
                      width={100}
                    />
                    <Body style={styles.daysText}>
                      <Plural
                        one={<Trans>day</Trans>}
                        other={<Trans>days</Trans>}
                        value={parseInt(daysToExpire, 10)}
                      />
                    </Body>
                  </View>
                  {isDaysInputErrorVisible && (
                    <Body style={styles.textInputErrorText}>
                      <Trans>
                        Must be between {MIN_DAYS} and {MAX_DAYS}
                      </Trans>
                    </Body>
                  )}
                </View>
              )}
            </View>
            <Button
              disabled={
                isPending ||
                (!isExpirationOptionSelected && (daysToExpire === "" || isDaysInputErrorVisible))
              }
              onPress={onPressGenerateShareLink}
              text={isPending ? _(msg`Generating…`) : _(msg`Generate Share Link`)}
            />
          </>
        ) : (
          <View style={styles.successContainer}>
            <Heading level="3">
              <Plural
                one={<Trans>Your merit is ready to be shared</Trans>}
                other={<Trans>Your merits are ready to be shared</Trans>}
                value={selectedMeritIds.length}
              />
            </Heading>
            <View style={styles.successLinkContainer}>
              <Body>{generatedShareLink}</Body>
            </View>
            <View style={styles.successButtonsContainer}>
              <ShareOrCopyLinkButton link={generatedShareLink} />
            </View>
          </View>
        )}
      </View>
    </KeyboardAvoidingView>
  );
};
