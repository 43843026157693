import { Body, Button, useTheme } from "@merit/frontend-components";
import { type FC, useMemo } from "react";
import { Helpers } from "@merit/frontend-utils";
import { Plural, Trans } from "@lingui/macro";
import { ShareOrCopyLink } from "./ShareOrCopyLink";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { TruncatedBody } from "@src/components";
import { getFormattedDate, getShareMeritsLink, openExternalLink } from "@src/utils";
import { useLingui } from "@lingui/react";
import { useRoute } from "@react-navigation/native";
import type { Share } from "@merit/share-merits-client";
import type { TextStyle, ViewStyle } from "react-native";

const { Some } = Helpers;

type Props = {
  readonly shareMerit: Share;
  readonly onPressDelete: (shareId: Share["shareID"]) => void;
};

const SharedLinksListItem: FC<Props> = ({ onPressDelete, shareMerit }) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly item: ViewStyle;
    readonly shareLink: TextStyle;
    readonly buttonsContainer: ViewStyle;
  }>({
    buttonsContainer: {
      flexDirection: "row",
      gap: theme.spacing.m,
    },
    item: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: theme.spacing.m,
    },
    shareLink: {
      fontWeight: theme.fontWeights.semiBold,
      ...theme.fontSizes.s,
      color: theme.colors.text.link,
      textDecorationLine: "underline",
    },
  });

  const route = useRoute();
  const { _ } = useLingui();

  const formattedDate = useMemo(
    () => (Some(shareMerit.createdAt) ? getFormattedDate(new Date(shareMerit.createdAt)) : "--"),
    [shareMerit.createdAt]
  );

  const sharedLink = useMemo(() => getShareMeritsLink(shareMerit.shareID), [shareMerit.shareID]);

  return (
    <View style={styles.item}>
      <TouchableOpacity
        onPress={() => {
          openExternalLink(sharedLink);
        }}
      >
        <Body
          size="l"
          style={styles.shareLink}
          testProps={{
            elementId: "sharedLink",
            elementName: "SharedLinksListItem",
            screenName: route.name,
          }}
        >
          <Plural
            one={
              <Trans>
                # merit shared{"\n"}on {formattedDate}
              </Trans>
            }
            other={
              <Trans>
                # merits shared{"\n"}on {formattedDate}
              </Trans>
            }
            value={shareMerit.containerCount}
          />
        </Body>
      </TouchableOpacity>
      <TruncatedBody
        style={theme.fontSizes.s}
        testProps={{
          elementId: "expiresOn",
          elementName: "SharedLinksListItem",
          screenName: route.name,
        }}
      >
        {Some(shareMerit.expiration) ? (
          <View>
            <Body>
              <Trans>Expires on</Trans>
            </Body>
            <Body>{getFormattedDate(new Date(shareMerit.expiration))}</Body>
          </View>
        ) : (
          <Trans>Never expires</Trans>
        )}
      </TruncatedBody>
      <View style={styles.buttonsContainer}>
        <ShareOrCopyLink link={sharedLink} />
        <Button
          iconLeft="closeMediumAction"
          onPress={() => {
            onPressDelete(shareMerit.shareID);
          }}
          shape="square"
          testProps={{
            elementId: "deleteButton",
            elementName: "SharedLinksListItem",
            screenName: route.name,
          }}
          type="destructive"
        />
      </View>
    </View>
  );
};

export { SharedLinksListItem };
