/* eslint-disable no-underscore-dangle */

import { useAlertStore } from "@src/stores/alert";
import { useCallback } from "react";
import { useLayoutType } from "./useLayoutType";
import { v4 as uuid } from "uuid";

export type SendAlertParams = {
  readonly id?: string;
  readonly text1: string;
  readonly text2?: string;
  readonly type: "error" | "info" | "success" | "warning";
};

const useAlerts = () => {
  const { deleteAlert, pushAlert } = useAlertStore();
  const layoutType = useLayoutType();

  const sendAlert = useCallback(
    ({ id, text1, text2, type }: SendAlertParams) => {
      pushAlert({
        closable: true,
        id: id ?? uuid(),
        onPressDelete: __id => {
          deleteAlert(__id);
        },
        size: layoutType === "desktop" ? "medium" : "small",
        text: text2 === undefined ? text1 : text2,
        title: text2 === undefined ? undefined : text1,
        type,
      });
    },
    [pushAlert, deleteAlert, layoutType]
  );

  return { deleteAlert, sendAlert };
};

export { useAlerts };
