import { SharedLinksScreen as DesktopSharedLinksScreen } from "./SharedLinksScreen.desktop";
import { SharedLinksScreen as MobileSharedLinksScreen } from "./SharedLinksScreen.mobile";
import { useLayoutType } from "@src/hooks";

const SharedLinksScreen = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopSharedLinksScreen />;
  }

  return <MobileSharedLinksScreen />;
};

export { SharedLinksScreen };
