import { Body, Button, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Plural, Trans, msg } from "@lingui/macro";
import { ShareOrCopyLink } from "./ShareOrCopyLink";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { TruncatedBody } from "@src/components";
import { getFormattedDate, getShareMeritsLink, openExternalLink } from "@src/utils";
import { useLingui } from "@lingui/react";
import { useRoute } from "@src/hooks";
import React, { useMemo } from "react";
import type { FC } from "react";
import type { Share } from "@merit/share-merits-client";
import type { TextStyle, ViewStyle } from "react-native";

type Props = {
  readonly shareMerit: Share;
  readonly onPressDelete: (shareId: Share["shareID"]) => void;
};

const { Some } = Helpers;

const SharedLinksListItem: FC<Props> = ({ onPressDelete, shareMerit }) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly item: ViewStyle;
    readonly linkContainer: ViewStyle;
    readonly linkExpiresText: TextStyle;
    readonly linkText: TextStyle;
    readonly buttonsContainer: ViewStyle;
  }>({
    buttonsContainer: {
      flexDirection: "row",
      gap: theme.spacing.m,
    },
    item: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    linkContainer: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 5,
    },
    linkExpiresText: {
      color: theme.colors.text.subdued,
      flexGrow: 1,
    },
    linkText: {
      color: theme.colors.text.link,
      fontWeight: theme.fontWeights.semiBold,
      textDecorationLine: "underline",
    },
  });

  const route = useRoute();
  const { _ } = useLingui();

  const formattedDate = useMemo(
    () => (Some(shareMerit.createdAt) ? getFormattedDate(new Date(shareMerit.createdAt)) : "--"),
    [shareMerit.createdAt]
  );

  const sharedLink = useMemo(() => getShareMeritsLink(shareMerit.shareID), [shareMerit.shareID]);

  return (
    <View style={styles.item}>
      <TouchableOpacity
        onPress={() => {
          openExternalLink(sharedLink);
        }}
        style={styles.linkContainer}
      >
        <TruncatedBody
          size="l"
          style={styles.linkText}
          testProps={{
            elementId: "meritLink",
            elementName: "SharedLinksListItem",
            screenName: route.name,
          }}
        >
          <Plural
            one={<Trans># merit shared on {formattedDate}</Trans>}
            other={<Trans># merits shared on {formattedDate}</Trans>}
            value={shareMerit.containerCount}
          />
        </TruncatedBody>
      </TouchableOpacity>
      <TruncatedBody
        style={styles.linkExpiresText}
        testProps={{
          elementId: "expiresOn",
          elementName: "SharedLinksListItem",
          screenName: route.name,
        }}
      >
        {Some(shareMerit.expiration) ? (
          <View>
            <Body>
              <Trans>Expires on</Trans>
            </Body>
            <Body>{getFormattedDate(new Date(shareMerit.expiration))}</Body>
          </View>
        ) : (
          <Trans>Never expires</Trans>
        )}
      </TruncatedBody>
      <View style={styles.buttonsContainer}>
        <ShareOrCopyLink link={sharedLink} />
        <Button
          onPress={() => {
            onPressDelete(shareMerit.shareID);
          }}
          testProps={{
            elementId: "deleteButton",
            elementName: "SharedLinksListItem",
            screenName: route.name,
          }}
          text={_(msg`Delete`)}
          type="destructive"
        />
      </View>
    </View>
  );
};

export { SharedLinksListItem };
