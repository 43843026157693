import { HEADER_HEIGHT } from "@src/utils/constants/sizes";
import { Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { FC } from "react";

export const SideBarContentHeader: FC = () => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const { _ } = useLingui();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "space-between",
    },
    headerItem: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.headerItem}>
        <Heading
          bold
          level="2"
          {...getTestProps({
            elementId: "heading",
            elementName: "SideBarContentHeader",
          })}
        >
          {_(msg({ context: "title", message: "Active Shared Links" }))}
        </Heading>
      </View>
    </View>
  );
};
