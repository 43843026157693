import { getLocalConfig } from "../getLocalConfig";
import type { Share } from "@merit/share-merits-client";

type StringMap = Readonly<Record<string, string>>;

export const toQueryString = (params: StringMap): string =>
  `?${Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&")}`;

export const getShareMeritsLink = (shareId: Share["shareID"]) => {
  if (shareId.length === 0) {
    throw new Error("shareId must not be empty");
  }

  const { shareMeritsFrontend } = getLocalConfig();

  return new URL(`share/${shareId}`, shareMeritsFrontend.url).href;
};
