import { BottomTabsNavigator } from "@src/navigation";
import { LoggedInDrawerContent } from "@src/components";
import { Settings, SharedLinksScreen } from "@src/screens";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useFeatureFlags } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { ScreenParamList } from "../types";

export type DrawerParamList = Pick<
  ScreenParamList,
  "BottomTabsNavigator" | "OnboardingMerits" | "Settings" | "SharedLinks"
>;

const Drawer = createDrawerNavigator<DrawerParamList>();

export const DrawerNavigator = () => {
  const featureFlags = useFeatureFlags();
  const { _ } = useLingui();

  // note: screens under the Drawer.Navigator do not have drawerLabels, since we do not use
  // the default DrawerItem component. See LoggedInDrawerContent and LoggedInDrawerItem
  return (
    <Drawer.Navigator
      drawerContent={props => <LoggedInDrawerContent {...props} />}
      initialRouteName="BottomTabsNavigator"
      screenOptions={{ headerShown: false }}
    >
      <Drawer.Screen component={BottomTabsNavigator} name="BottomTabsNavigator" />
      {featureFlags.showSettings === true ? (
        <Drawer.Screen component={Settings} name="Settings" />
      ) : null}
      {featureFlags.showMemberAppShareMeritsFeature === true ? (
        <Drawer.Screen component={SharedLinksScreen} name="SharedLinks" />
      ) : null}
    </Drawer.Navigator>
  );
};
