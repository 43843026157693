import { Platform } from "react-native";
// eslint-disable-next-line no-restricted-imports
import { setStringAsync, setUrlAsync as setUrlAsyncExpo } from "expo-clipboard";

/**
 * Simple wrapper that uses `setUrlAsync`  for iOS, and `setStringAsync` for all other
 * platforms. Per the
 * {@link https://docs.expo.dev/versions/latest/sdk/clipboard/#seturlasyncurl|Expo docs for `setUrlAsync`},
 * it is iOS only.
 */
export const setUrlAsync = (str: string) => {
  const method = Platform.OS === "ios" ? setUrlAsyncExpo : setStringAsync;

  return method(str);
};
