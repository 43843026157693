import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useGetSharesQueryKey } from "./useGetShares";
import { useLingui } from "@lingui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useShareMeritsApi } from "./useShareMeritsApi";
import type { CreateShareProperties } from "@merit/share-merits-client";

export const useCreateShareMerits = () => {
  const { api } = useShareMeritsApi();
  const { deleteAlert, sendAlert } = useAlerts();
  const { _ } = useLingui();
  const queryClient = useQueryClient();
  const getSharesQueryKey = useGetSharesQueryKey();

  return useMutation({
    mutationFn: (requestBody: CreateShareProperties) =>
      api.createShare({
        properties: requestBody,
      }),
    onError: err => {
      Log.error(`Error creating share link`, { error: err });

      deleteAlert("useCreateShareMerits-inProgress");
      sendAlert({
        id: "useCreateShareMerits-Error",
        text1: _(msg`Error creating your share link`),
        text2: String(err),
        type: "error",
      });
    },
    onMutate: () => {
      sendAlert({
        id: "useCreateShareMerits-inProgress",
        text1: _(msg`Creating your shareable link…`),
        type: "info",
      });
    },
    onSuccess: async () => {
      deleteAlert("useCreateShareMerits-inProgress");
      sendAlert({
        id: "useCreateShareMerits-Success",
        text1: _(msg`Your share link has been successfully created`),
        type: "success",
      });
      await queryClient.invalidateQueries({ queryKey: getSharesQueryKey });
    },
  });
};
