import { Button } from "@merit/frontend-components";
import { msg } from "@lingui/macro";
import { setUrlAsync } from "@src/utils";
import { useAlerts } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { Props } from "./common";

export const ShareOrCopyLinkButton = ({ link }: Props) => {
  const { _ } = useLingui();
  const { sendAlert } = useAlerts();

  return (
    <Button
      onPress={async () => {
        await setUrlAsync(link);
        sendAlert({
          id: "copy-success",
          text1: _(msg`Success`),
          text2: _(msg`Link copied to clipboard`),
          type: "success",
        });
      }}
      text={_(msg`Copy`)}
    />
  );
};
