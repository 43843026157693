import { Button, useTheme } from "@merit/frontend-components";
import { Header, Icon as PEIcon } from "@src/components";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useFeatureFlags, useNavigation } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { ViewStyle } from "react-native";

type MeritsHeaderProps = {
  readonly isPreviewButtonEnabled: boolean;
  readonly isShareInProgress: boolean;
  readonly onPressCancel: () => void;
  readonly onPressPreview: () => void;
  readonly onPressShare: () => void;
};

export const MeritsHeader = ({
  isPreviewButtonEnabled,
  isShareInProgress,
  onPressCancel,
  onPressPreview,
  onPressShare,
}: MeritsHeaderProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly buttonsContainer: ViewStyle;
  }>({
    buttonsContainer: {
      flexDirection: "row",
      gap: theme.spacing.s,
    },
  });

  const { showMemberAppShareMeritsFeature } = useFeatureFlags();
  const navigation = useNavigation();
  const { _ } = useLingui();

  const handleLeftOnPress = () => {
    navigation.toggleDrawer();
  };

  const shareButtons = isShareInProgress ? (
    <View style={styles.buttonsContainer}>
      <Button
        iconLeft="closeMediumAction"
        onPress={onPressCancel}
        shape="square"
        type="secondary"
      />
      <Button
        disabled={!isPreviewButtonEnabled}
        iconLeft={isPreviewButtonEnabled ? "sendMediumAction" : "sendMediumDisabled"}
        onPress={onPressPreview}
        shape="square"
        type="primary"
      />
    </View>
  ) : (
    <Button iconLeft="uploadMediumAction" onPress={onPressShare} shape="square" type="secondary" />
  );

  return (
    <Header
      leftElement={<PEIcon name="menu_left" size={theme.fontSizes.xl.fontSize} />}
      leftOnPress={handleLeftOnPress}
      rightElement={showMemberAppShareMeritsFeature === true ? shareButtons : undefined}
      title={_(msg({ context: "title", message: "Merits" }))}
    />
  );
};
