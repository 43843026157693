import { Button } from "@merit/frontend-components";
import { msg } from "@lingui/macro";
import { setUrlAsync } from "@src/utils";
import { useAlerts, useLayoutType } from "@src/hooks";
import { useCallback } from "react";
import { useLingui } from "@lingui/react";
import type { Props } from "./common";

export const ShareOrCopyLink = ({ link }: Props) => {
  const { _ } = useLingui();
  const { sendAlert } = useAlerts();
  const layoutType = useLayoutType();

  const onPressCopy = useCallback(async () => {
    await setUrlAsync(link);
    sendAlert({
      id: "copy-success",
      text1: _(msg`Success`),
      text2: _(msg`Link copied to clipboard`),
      type: "success",
    });
  }, [_, link, sendAlert]);

  if (layoutType === "mobile") {
    return (
      <Button
        iconLeft="linkMediumHighlight"
        onPress={onPressCopy}
        shape="square"
        type="secondary"
      />
    );
  }

  return <Button onPress={onPressCopy} text={_(msg`Copy Link`)} />;
};
